import { useEffect } from 'react';

const EmailLinkConverter = () => {
  useEffect(() => {
    const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;

    const convertEmailsToLinks = (element) => {
      if (element.nodeType === 3) { // Text node
        const text = element.nodeValue;
        const matches = text.match(emailRegex);
        if (matches) {
          const span = document.createElement('span');
          let lastIndex = 0;
          matches.forEach(match => {
            const index = text.indexOf(match, lastIndex);
            if (index > lastIndex) {
              span.appendChild(document.createTextNode(text.substring(lastIndex, index)));
            }
            const link = document.createElement('a');
            link.href = `mailto:${match}`;
            link.textContent = match;
            span.appendChild(link);
            lastIndex = index + match.length;
          });
          if (lastIndex < text.length) {
            span.appendChild(document.createTextNode(text.substring(lastIndex)));
          }
          element.parentNode.replaceChild(span, element);
        }
      } else if (element.nodeType === 1 && element.childNodes && !/^(a|script|style|textarea)$/i.test(element.tagName)) { // Element node
        Array.from(element.childNodes).forEach(child => convertEmailsToLinks(child));
      }
    };

    const observeMutations = () => {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach(mutation => {
          if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
            mutation.addedNodes.forEach(node => {
              convertEmailsToLinks(node);
            });
          }
        });
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    };

    convertEmailsToLinks(document.body);
    observeMutations();
  }, []);

  return null;
};

export default EmailLinkConverter;