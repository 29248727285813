import React, { useEffect } from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import './App.css';
import Home from './components/Pages/Home';
import Venue from './components/Pages/Venue';
import Info from './components/Pages/Info';
import Register from './components/Pages/Register';
import Transport from './components/InfoTransport';
import Junior from './components/Pages/Junior';
import U17 from './components/Pages/U17';
import U15 from './components/Pages/U15';
import U13 from './components/Pages/U13';
import { gtag } from './gtag'; // Import the gtag function
import EmailLinkConverter from './components/EmailLinkConverter'; // Import the EmailLinkConverter component

function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    gtag('event', 'page_view', {
      page_path: location.pathname + location.search,
    });
  }, [location]);
}

function App() {
  return (
    <React.Fragment>
      <Router>
        <ScrollToTop />
        <Navbar />
        <RouteTrackingWrapper /> {/* Add this component inside Router */}
        <EmailLinkConverter /> {/* Add the EmailLinkConverter component */}
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path='/venue' component={Venue} />
          <Route path='/info' component={Info} />
          <Route path='/transport' component={Transport} />
          <Route path='/register' component={Register} />
          <Route path='/junior' component={Junior} />
          <Route path='/u17' component={U17} />
          <Route path='/u15' component={U15} />
          <Route path='/u13' component={U13} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

function RouteTrackingWrapper() {
  usePageTracking();
  return null;
}

export default App;